import { render, staticRenderFns } from "./HelloWorld.vue?vue&type=template&id=5cc7f8f0&scoped=true"
import script from "./HelloWorld.vue?vue&type=script&lang=js"
export * from "./HelloWorld.vue?vue&type=script&lang=js"
import style0 from "./HelloWorld.vue?vue&type=style&index=0&id=5cc7f8f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc7f8f0",
  null
  
)

export default component.exports